<template>
  <div class="page" :loaded="Boolean(caseStudy)">
    <section class="case" v-if="caseStudy">
      <div class="wrapper">
        <div class="case-image" :style="{ backgroundImage: `url(${caseStudy.image})` }">
          <div class="case-info">
            <div class="case-photo">
              <img :src="caseStudy.logo" :alt="caseStudy.name">
            </div>
            <div class="case-name">
              <div class="case-role">{{caseStudy.role}}</div>
              <h4>{{caseStudy.name}}</h4>
              <div class="case-company">{{caseStudy.company}}</div>
            </div>
          </div>
        </div>
        <div class="case-title">
          <h3>{{caseStudy.company}}</h3>
          <!-- <div class="case-sectors" v-if="caseStudy.sectors">{{caseStudy.sectors.join(' - ')}}</div> -->
          <div class="case-description">{{ caseStudy.description }}</div>
        </div>
      </div>
      <div class="wrapper">
        <div class="case-content">
          <div class="case-faq-list" v-for="(column, j) in columns" :key="`column-${j}`">
            <div class="case-faq" v-for="(faq, i) in column" :key="i">
              <h5>{{faq.question}}</h5>
              <div class="case-faq-answer" v-html="faq.answer.replace(/\n/g, '<br>')"></div>
            </div>
          </div>
          <!-- <div class="case-image-list">
            <div class="case-media-container" :is-video="media.type === 'video'" v-for="media in caseStudy.media_links" :key="media.id">
              <img @click="selectedImage = media" :src="getThumbnail(media)" alt="">
            </div>
          </div> -->
        </div>
        <div class="case-share">
          <!-- <h5>Share this case study</h5>
          <div class="socials">
            <a :href="`https://www.facebook.com/sharer/sharer.php?u=${pageLink}`" class="social" target="_blank">
              <Icon icon="facebook-f" size="small" />
            </a>
            <a :href="`https://twitter.com/home?status=${pageLink}`" class="social" target="_blank">
              <Icon icon="twitter" size="small" />
            </a>
            <a :href="`https://www.linkedin.com/shareArticle?mini=true&url=${pageLink}`" class="social" target="_blank">
              <Icon icon="linkedin-in" size="small" />
            </a>
            <a :href="`mailto:?body=${pageLink}`" class="social">
              <Icon icon="envelope" type="far" size="small" />
            </a>
            <a ref="link" :data-clipboard-text="pageLink" :active="copied" class="social">
              <Icon icon="link" type="fas" size="small" />
              <div class="valid">Copied!</div>
            </a>
          </div> -->
          <router-link class="btn medium" to="/case-studies#clients">
            Return to our clients
          </router-link>
        </div>
      </div>
    </section>
    <ContactForm />
    <div class="overlay" :visible="!!selectedImage" @click="selectedImage = null">
      <div class="overlay-content" v-if="selectedImage">
        <img v-if="selectedImage.type === 'image'" :src="selectedImage.url" alt="" class="overlay-image" @click.stop>
        <iframe class="overlay-iframe" v-else :src="getVideoLink(selectedImage)" frameborder="0" allow="autoplay; encrypted-media" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
      </div>
      <a class="overlay-close" @click="selectedImage = null">
        <Icon icon="times" type="fas" />
      </a>
    </div>
  </div>
</template>

<script>
import ContactForm from "./ContactForm.vue";
import Icon from "./Icon";
import { mapGetters } from "vuex";
// import Clipboard from 'clipboard';

export default {
  name: "CaseStudy",
  components: {
    Icon,
    ContactForm,
  },
  data() {
    return {
      caseStudy: null,
      copied: false,
      columns: [],
      selectedImage: null,
    };
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    slug() {
      return this.$route.params.slug;
    },
    pageLink() {
      return window.location.href;
    },
    ...mapGetters({
      isMobile: "getMobile",
      testMode: "getTest",
    }),
  },
  watch: {
    testMode() {
      this.getItems();
    },
    slug() {
      this.getItems();
    },
  },
  methods: {
    getThumbnail(media) {
      if (media.type === "image") return media.url;
      if (media.url.includes("youtube")) {
        const m = media.url.match(/\?v=([a-zA-Z0-9-_]+)($|&)/);
        if (m) return `https://i.ytimg.com/vi/${m[1]}/hqdefault.jpg`;
      } else if (media.url.includes("vimeo")) {
        const id = media.url.split("/").pop();
        if (id && !isNaN(id)) return `https://i.vimeocdn.com/video/${id}.jpg`;
      }
      return null;
    },
    getVideoLink(media) {
      if (media.type === "image") return media.url;
      if (media.url.includes("youtube")) {
        const m = media.url.match(/\?v=([a-zA-Z0-9-_]+)($|&)/);
        if (m) return `https://www.youtube.com/embed/${m[1]}`;
      } else if (media.url.includes("vimeo")) {
        const id = media.url.split("/").pop();
        if (id && !isNaN(id)) return `https://player.vimeo.com/video/${id}`;
      }
      return null;
    },
    getItems() {
      if (this.caseRef) this.caseRef();
      if (!this.slug) return;
      this.caseRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}cases`)
        .where('company', '==', this.slug)
        .onSnapshot((data) => {
          this.caseStudy = data.size ? data.docs[0].data() : null;
          if (this.caseStudy && this.caseStudy.faq) {
            this.columns = this.caseStudy.faq.reduce((columns, faq, i) => {
              columns[i % 2].push(faq);
              return columns;
            }, [[], []]);
          } else {
            this.columns = [[], []];
          }
          // if (this.caseStudy) {
          //   this.$nextTick().then(() => {
          //     const clip = new Clipboard(this.$refs.link);
          //     clip.on('success', () => {
          //       clearTimeout(this.copiedTO);
          //       this.copied = true;
          //       this.copiedTO = setTimeout(() => this.copied = false, 1500);
          //     })
          //   })
          // }
        });
    },
  },
  mounted: function () {
    this.getItems();
  },
  beforeDestroy: function () {
    if (this.caseRef) this.caseRef();
  },
};
</script>

<style scoped>
.case {
  margin-bottom: 80px;
}

.case .case-image {
  display: block;
  height: 690px;
  border-radius: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 144px;
}

.case .case-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  padding: 0 96px;
  border-radius: 12px;
  background: linear-gradient(180deg, rgba(14, 58, 90, 0.00) 65.56%, #0E3A5A 100%);
}

.case .case-info .case-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: right;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 32px;
  width: 300px;
}
.case .case-info .case-name h4 {
  font-size: 1.5rem;
  color: var(--Dark-Blue);
  font-weight: 600;
  background: #fff;
  padding: 8px 32px;
  border-radius: 4px;
  margin: 8px 0;
}
.case .case-info .case-name > div {
  padding: 0 32px;
}

.case .case-info .case-photo {
  width: 196px;
  height: 196px;
  flex-shrink: 0;
  margin-bottom: -98px;
}

.case .case-info .case-photo img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  background: #fff;
}

.case .case-logo {
  width: 184px;
  height: 64px;
  object-fit: contain;
  margin-bottom: 8px;
}

.case .case-description {
  margin-top: 16px;
}

.case .case-title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.16666;
  padding: 0 96px;
}

.case .case-title h3 {
  font-size: 4rem;
  margin-bottom: 32px;
}

.case .case-title .case-sectors {
  color: #0A7E8C;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 12px;
}

.case .case-content {
  margin-top: 168px;
}

.case .case-content .case-faq-list {
  padding: 0 96px;
}

.case .case-content .case-faq-list .case-faq {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 56px;
}

.case .case-content .case-faq-list .case-faq h5 {
  font-size: 2.25rem;
  font-weight: 600;
  width: 36%;
  line-height: 1.166666;
}

.case .case-content .case-image-list img {
  display: block;
  width: 100%;
  border-radius: 16px;
  margin-bottom: 42px;
  box-shadow: 0 12px 48px rgba(101, 102, 98, 0.12);
  cursor: pointer;
}

.case .case-content .case-image-list .case-media-container {
  position: relative;
}

.case .case-content .case-image-list .case-media-container[is-video]:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.32) url(../assets/images/wb_ic_play.svg) no-repeat center/48px auto;
  border-radius: 16px;
  pointer-events: none;
}

.case-faq .case-faq-answer {
  width: 64%;
  padding-left: 96px;
  font-size: 1.5rem;
  font-weight: 500;
}
.case-faq .case-faq-answer::after {
  content: '';
  display: block;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  height: 3px;
  margin-top: 56px;
}
.case-faq .case-faq-answer >>> ul {
  padding: initial;
  margin: initial;
  list-style: initial;
}

.case .case-share {
  margin: 96px 0;
  text-align: center;
}

.case .case-share .socials {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 96px;
}

.case .case-share .socials .social {
  margin: 0 6px;
  transition: color .2s ease-out;
}

#app[no-touch] .case .case-share .socials .social:hover {
  color: #0A7E8C;
}

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  transition: all 0.2s ease-in;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 64px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.overlay[visible] {
  visibility: visible;
  opacity: 1;
}

.overlay .overlay-image {
  display: block;
  max-width: 90%;
  max-height: 90%;
  border-radius: 16px;
}

.overlay .overlay-iframe {
  display: block;
  width: 960px;
  height: 540px;
  max-width: 90vw;
  max-height: 50.625vw;
  border-radius: 16px;
}

.overlay .overlay-close {
  position: absolute;
  top: 24px;
  right: 24px;
  color: #fff;
}

@media screen and (max-width: 1280px) {
  .case-faq .case-faq-answer {
    padding-left: 64px;
  }
  .case .case-content {
    margin-top: 96px;
  }
}

@media screen and (max-width: 1024px) {
  .case .case-image {
    height: 480px;
  }
  .case .case-info,
  .case .case-title,
  .case .case-content .case-faq-list {
    padding: 0 48px;
  }
  .case .case-content .case-faq-list .case-faq h5 {
    font-size: 2rem;
  }
  .case-faq .case-faq-answer {
    padding-left: 48px;
  }
}

@media screen and (max-width: 880px) {
  .case .case-content .case-faq-list .case-faq h5 {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 680px) {
  .case .case-content .case-faq-list .case-faq {
    flex-direction: column;
    align-items: stretch;
  }
  .case .case-content .case-faq-list .case-faq h5,
  .case-faq .case-faq-answer {
    width: auto;
    padding: 0;
  }
  .case .case-content .case-faq-list .case-faq h5 {
    margin-bottom: 24px;
  }
  .case .case-info .case-photo {
    width: 128px;
    height: 128px;
    margin-bottom: -64px;
    margin-right: 24px;
  }
}

@media screen and (max-width: 580px) {
  .case .case-title,
  .case .case-content .case-faq-list {
    padding: 0;
    font-size: 1.3125rem;
  }
  .case-faq .case-faq-answer {
    font-size: 1.3125rem;
  }
  .case .case-image {
    height: 400px;
    margin-bottom: 64px;
  }
  .case .case-info {
    flex-direction: column;
  }
  .case .case-info .case-photo {
    margin: 24px 0 0;
  }
}
</style>
